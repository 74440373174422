/* eslint-disable import/no-anonymous-default-export */
import { User } from 'models';
import nookies from 'nookies';
import { getErrorMessage, getResponseMessage } from 'utils/errorHandling';
import API from 'api/base';
import axios, { AxiosResponse, CancelToken } from 'axios';
import {
  ICampaignIndustries,
  ILoginRequest,
  ILoginResponse,
  IRecoverAccountResponse,
  IRegisterRequest,
  IUserOrganization,
  IMyUserInfo,
  IUpdateAccountResponse,
  IValidateUserResponse,
  IUserInvoice,
  IUserCard,
} from 'interface';

import { NextPageContext } from 'next';
import { number } from 'prop-types';
import {
  ICampaignFormat,
  IGeoCountries,
  ILocation,
  ILocationContentType,
  ILocationFull,
  INetflix,
} from 'interface/ILocation';
import { DateTime } from 'luxon';
import Dinero from 'dinero.js';
import { IMediaUpload } from 'interface/IMedia';
import { getUser } from '@api/ssr/user';
import { IMondayMedia, MondayState } from '../../context/monday/monday.reducer';
import { ScheduleDates } from '../../context/monday/monday.type';

interface IUserCredit {
  error: boolean;
  credit: number;
  creditValue: string;
}
const addVoucher = async (voucher: string): Promise<any> => {
  try {
    const result: any = await API({
      method: 'post',
      url: `credits/redeem-voucher`,
      data: { voucher },
    });
    if (result.data.data.status !== 202) {
      return {
        message: 'Voucher has been added',
        code: 'success',
      };
    } else {
      const priceNew = Dinero({ amount: result.data.data.newPrice, currency: result.data.data.currency }).toFormat();
      const priceOld = Dinero({ amount: result.data.data.value, currency: result.data.data.oldCurrency }).toFormat();
      return {
        message: 'Convert voucher value',
        code: 'warning',
        voucher: voucher,
        price: priceNew,
        priceOld: priceOld,
      };
    }
  } catch (e: any) {
    return {
      message: e.response.data.error.message,
      code: 'error',
    };
  }
};

const confirmConvertVoucherCurrency = async (voucher: string): Promise<any> => {
  try {
    const result: any = await API({
      method: 'post',
      url: `credits/redeem-voucher-confirm`,
      data: { voucher },
    });
    if (result.data.data.status !== 202) {
      return {
        message: 'Voucher has been added',
        code: 'success',
      };
    } else {
      const priceNew = Dinero({ amount: result.data.data.newPrice, currency: result.data.data.currency }).toFormat();
      const priceOld = Dinero({ amount: result.data.data.value, currency: result.data.data.oldCurrency }).toFormat();
      return {
        message: 'Convert voucher value',
        code: 'warning',
        voucher: voucher,
        price: priceNew,
        priceOld: priceOld,
      };
    }
  } catch (e: any) {
    return {
      message: e.response.data.error.message,
      code: 'error',
    };
  }
};

const addPercentageVoucher = async (code: string, campaignValue?: number): Promise<any> => {
  try {
    const result: any = await API({
      method: 'get',
      url: `credits/voucher/${code}`,
    });
    const res: any = result.data.data;
    const now = DateTime.utc().toISO();
    if (res.expire_date <= now) {
      return {
        message: 'Voucher has expired',
        code: 'error',
      };
    }

    if (campaignValue && res.conditions && res.conditions.minimum && res.conditions.minimum > campaignValue) {
      return {
        message:
          'This voucher can only be applied on campaigns bigger then ' +
          Dinero({ amount: Number(res.conditions.minimum), precision: 4, currency: res.currency }).toFormat(),
        code: 'error',
      };
    }

    if (res.type !== 'percentage' && res.type !== 'reusablefixed') {
      return {
        message: 'Voucher is not percentage or reausable. This type of voucher should be added in wallet section',
        code: 'error',
      };
    }

    if (res.redeemed === true) {
      return {
        message: 'Voucher already used',
        code: 'error',
      };
    }

    if (
      res.conditions &&
      res.conditions.max_uses &&
      res.no_of_uses &&
      res.conditions.max_uses <= Number(res.no_of_uses)
    ) {
      return {
        message: 'You already used this voucher too many times',
        code: 'error',
      };
    }

    if (res.used === true) {
      return {
        message: 'Voucher already used',
        code: 'error',
      };
    }

    if (res.type === 'percentage') {
      return {
        message: 'You have ' + res.value + '% discount on this campaign!',
        code: 'success',
        value: res.value,
        voucherCode: res.code,
        type: 'percentage',
      };
    } else if (res.type === 'reusablefixed') {
      return {
        message:
          'You have ' +
          Dinero({ amount: res.value * 100, precision: 4, currency: res.currency }).toFormat() +
          '$ discount on this campaign',
        code: 'success',
        value: res.value * 100,
        voucherCode: res.code,
        type: 'reusablefixed',
      };
    }
  } catch (e: any) {
    return {
      message: e.response.data.error,
      code: 'error',
    };
  }
};
const getUserCredit = async (id?: string): Promise<IUserCredit> => {
  try {
    const result: any = await API({
      method: 'get',
      url: `credits/get/${id || '0'}`,
    });
    const r: IUserCredit = {
      error: false,
      credit: result.data.data.result.sumVal,
      creditValue: result.data.data.result.amount,
    };
    return r;
  } catch (e: any) {
    const r: IUserCredit = {
      error: true,
      credit: 0,
      creditValue: '0',
    };
    return r;
  }
};
const recoverPassword = async (email: string): Promise<IRecoverAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `auth/request-password-change?v=v5`,
      data: {
        email,
      },
    });
    const r: IRecoverAccountResponse = {
      hasError: false,
      errorMessage: '',
      success: result.data.data.success,
      message: result.data.data.message,
    };
    return r;
  } catch (e: any) {
    const r: IRecoverAccountResponse = {
      hasError: true,
      errorMessage: getErrorMessage(e),
      message: '',
      success: false,
    };
    return r;
  }
};
const passwordChangeToken = async (password: string, token: string): Promise<IRecoverAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `/auth/password-change/token/${token}`,
      data: {
        password,
      },
    });

    const r: IRecoverAccountResponse = {
      hasError: false,
      errorMessage: '',
      success: result.data.data.success,
      message: result.data.data.message,
    };
    return r;
  } catch (e: any) {
    const r: IRecoverAccountResponse = {
      hasError: true,
      errorMessage: getErrorMessage(e),
      message: '',
      success: false,
    };
    return r;
  }
};

const resendCode = async (email: string): Promise<ILoginResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `/auth/validateToken/resend?v=v5`,
      data: {
        email,
      },
    });
    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: result.data.data.token.accessToken,
      expiresIn: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
    };
    return r;
  } catch (e: any) {
    const r: ILoginResponse = {
      hasError: true,
      accessToken: '',
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

const validateCodeEmail = async (code: string): Promise<ILoginResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `/auth/validateToken/email?v=v5`,
      data: {
        code,
      },
    });
    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: result.data.data.token.accessToken,
      expiresIn: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
    };
    return r;
  } catch (e: any) {
    const r: ILoginResponse = {
      hasError: true,
      accessToken: '',
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

const signin = async ({ email, password }: ILoginRequest): Promise<ILoginResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `auth/login?v=v5`,
      data: {
        email,
        password,
      },
    });
    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: result.data.data.token.accessToken,
      expiresIn: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
    };
    return r;
  } catch (e: any) {
    const r: ILoginResponse = {
      hasError: true,
      accessToken: '',
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

const register = async ({
  email,
  password,
  firstName,
  lastName,
  phone,
  referralcode,
  tokenC,
  iso,
  country,
}: IRegisterRequest): Promise<ILoginResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `auth/register-new?v=v5`,
      data: {
        email,
        password,
        firstName,
        lastName,
        phone,
        referralcode,
        iso,
        country,
        tokenC,
      },
    });
    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: result.data.data.token.accessToken,
      expiresIn: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
    };
    return r;
  } catch (e: any) {
    if (!(e.response.data.error.message === 'Validation Failed')) {
      const r: ILoginResponse = {
        hasError: true,
        accessToken: '',
        errorMessage: getErrorMessage(e),
      };
      return r;
    } else {
      const r: ILoginResponse = {
        hasError: false,
        accessToken: '',
        errorMessage: getErrorMessage(e),
      };
      return r;
    }
  }
};
const validateEmail = async (code: string): Promise<IValidateUserResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `/auth/validateToken/email?v=v5`,
      data: {
        code,
      },
    });
    const r: IValidateUserResponse = {
      accessToken: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
      expiresIn: result.data.data.token.expiresIn,
      email: result.data.data.email,
      errorMessage: getResponseMessage(result.data.data.message),
      hasError: result.data.data.status !== 200,
    };
    return r;
  } catch (e: any) {
    const r: IValidateUserResponse = {
      accessToken: '',
      refreshToken: '',
      email: '',
      hasError: true,
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

const changeEmail = async (code: string): Promise<IValidateUserResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `/auth/change-email?v=v5`,
      data: {
        code,
      },
    });
    const r: IValidateUserResponse = {
      accessToken: result.data.data.token.accessToken,
      refreshToken: result.data.data.token.refreshToken,
      expiresIn: result.data.data.token.expiresIn,
      email: result.data.data.email,
      errorMessage: result.statusText,
      hasError: result.data.code !== 200,
    };
    return r;
  } catch (e: any) {
    const r: IValidateUserResponse = {
      accessToken: '',
      refreshToken: '',
      email: '',
      hasError: true,
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

type ICompanyData = {
  regNumber?: string;
  companyId: string;
  additionalId: string;
  name: string;
  firstName: string;
  lastName: string;
  vat: string;
  companyType: string;
  address: {
    iso3: string;
    country: string;
    city: string;
    address: string;
    locationId: string;
  };
};

const setUserCompanyData = async (data: ICompanyData): Promise<IUpdateAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `users/me/companyupdate-new?v=v5`,
      data,
    });
    const r: IUpdateAccountResponse = {
      hasError: result.data.data.status !== 200,
      existing: result.data.data.existing,
      redirect: result.data.data.redirect,
      message: getResponseMessage(result.data.data.message),
      token: result.data.data.token,
    };
    return r;
  } catch (e: any) {
    const r: IUpdateAccountResponse = {
      hasError: true,
      existing: false,
      redirect: false,
      message: getErrorMessage(e),
    };
    return r;
  }
};

const registercompany = async (data: ICompanyData): Promise<IUpdateAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `users/update-profile`,
      data: {
        name: data.name,
        regNumber: data.regNumber,
        additionalId: data.additionalId,
        companyId: data.companyId,
        id: data.companyId,
        vat: data.vat,
        address: data.address,
      },
    });
    const r: IUpdateAccountResponse = {
      hasError: result.data.data.status !== 200,
      existing: result.data.data.existing,
      redirect: result.data.data.redirect,
      message: getResponseMessage(result.data.data.message),
    };
    return r;
  } catch (e: any) {
    const r: IUpdateAccountResponse = {
      hasError: true,
      existing: false,
      redirect: false,
      message: getErrorMessage(e),
    };
    return r;
  }
};

const updateOnboarding = async ({ sw }: any): Promise<IUpdateAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `users/me/onboarding`,
      data: {
        onboarding: sw,
      },
    });
    const r: any = {
      hasError: result.status !== 200,
      token: result.data.token,
      message: getResponseMessage(result.data.message),
    };
    return r;
  } catch (e: any) {
    const r: any = {
      hasError: true,
      token: {
        accessToken: '',
        refreshToken: '',
        expiresIn: new Date(),
      },
      message: getErrorMessage(e),
    };
    return r;
  }
};

const updateAccount = async ({ email, firstName, lastName, phone }: IMyUserInfo): Promise<IUpdateAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `users/me/update?v=v5`,
      data: {
        email,
        firstName,
        lastName,
        phone,
      },
    });
    const r: IUpdateAccountResponse = {
      hasError: result.data.data.status !== 200,
      existing: result.data.data.existing,
      redirect: result.data.data.redirect,
      token: result.data.data.token,
      message: getResponseMessage(result.data.data.message),
    };
    return r;
  } catch (e: any) {
    const r: IUpdateAccountResponse = {
      hasError: true,
      existing: false,
      token: {
        accessToken: '',
        refreshToken: '',
        expiresIn: new Date(),
      },
      redirect: false,
      message: getErrorMessage(e),
    };
    return r;
  }
};

const changePassword = async (oldPassword: string, newPassword: string): Promise<IUpdateAccountResponse> => {
  try {
    const result = await API({
      method: 'post',
      url: `users/me/update-password?v=v5`,
      data: {
        oldPassword,
        newPassword,
      },
    });
    const r: IUpdateAccountResponse = {
      hasError: result.data.data.status !== 200,
      existing: false,
      redirect: false,
      message: getResponseMessage(result.data.data.message),
    };
    return r;
  } catch (e: any) {
    const r: IUpdateAccountResponse = {
      hasError: true,
      existing: false,
      redirect: false,
      message: getErrorMessage(e),
    };
    return r;
  }
};
const getInvoice = async (uuid: string): Promise<any> => {
  // const result = await REST.get(`/invoice/get/${invoice.uuid}`, {
  //   responseType: 'blob',
  // });
  const result = await API({
    method: 'get',
    url: `/invoice/get/${uuid}`,
    responseType: 'blob',
  });

  return result.data;
};
const getUserInvoice = async (ctx?: NextPageContext): Promise<IUserInvoice[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `/invoice/list`,
      },
      ctx
    );
    const data: IUserInvoice[] = response.data.data;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getNotificationsAll = async (ctx?: NextPageContext): Promise<any> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `/users/notifier/all`,
      },
      ctx,
      null
    );
    const data: any = response.data.data;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getNotificationsNew = async (ctx?: NextPageContext): Promise<any> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `/users/notifier/new`,
      },
      ctx,
      null
    );
    const data: any = response.data.data;
    return data;
  } catch (e: any) {
    console.log('e', e);
    return {
      notifications: [],
    };
  }
};

const addSurveyAnswer = async (surveyForm: any): Promise<any> => {
  try {
    const response = await API({
      method: 'post',
      url: `/survey/add`,
      data: {
        surveyForm,
      },
    });
    const r: any = {
      message: response.data.message,
      token: response.data.token,
    };
    return r;
  } catch (e: any) {
    console.log('e', e);
    return {
      notifications: [],
    };
  }
};

const removePayment = async (cardId: string): Promise<any> => {
  try {
    const response = await API({
      method: 'post',
      url: `/payment/remove`,
      data: { paymentId: cardId },
    });
  } catch (e: any) {
    throw new Error(e);
  }
};

const setDefaultPayment = async (cardId: string): Promise<any> => {
  try {
    const response = await API({
      method: 'post',
      url: `/payment/set-default`,
      data: { paymentId: cardId },
    });
  } catch (e: any) {
    throw new Error(e);
  }
};
const getUserNotifications = async (ctx?: NextPageContext): Promise<string> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `/users/get-notifications`,
      },
      ctx
    );
    return response.data.data.notifications;
  } catch (e: any) {
    return '';
  }
};

const setUserNotifications = async (value: string): Promise<string> => {
  try {
    const response = await API({
      method: 'post',
      url: `/users/update-notifications`,
      data: { notifications: value },
    });
    return response.data.data.notifications;
  } catch (e: any) {
    return '';
  }
};
const basePath = process.env.NEXT_PUBLIC_API_BASEPATH;

const takeUser = async (token: string, uuid: string, ctx?: NextPageContext): Promise<ILoginResponse> => {
  try {
    const response = await API({
      method: 'post',
      url: `${basePath}/auth/takeuser?v=v5`,
      data: {
        token,
        uuid,
      },
    });
    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: response.data.data.token.accessToken,
      expiresIn: response.data.data.token.accessToken,
      refreshToken: response.data.data.token.refreshToken,
    };
    return r;
  } catch (e: any) {
    const r: ILoginResponse = {
      hasError: true,
      accessToken: '',
      errorMessage: getErrorMessage(e),
    };
    return r;
  }
};

const getUserPayment = async (ctx?: NextPageContext): Promise<IUserCard[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `/payment/list`,
      },
      ctx
    );
    const data: IUserCard[] = response.data.data.result;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getUserCreditsLog = async (ctx?: NextPageContext): Promise<IUserCard[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `credits/get/0`,
      },
      ctx
    );
    const data: any = response.data.data.result.transactions;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getUserData = async (ctx?: NextPageContext): Promise<IMyUserInfo> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `users/me`,
      },
      ctx
    );

    const data: IMyUserInfo = response.data.data;
    return data;
  } catch (e: any) {
    console.log('eee', e);
    throw new Error(e);
  }
};

const getUserMediaLibrary = async (ctx?: NextPageContext): Promise<IMediaUpload[]> => {
  try {
    const response: AxiosResponse<{
      data: IMediaUpload[];
    }> = await API(
      {
        method: 'get',
        url: `users/me/media-library`,
      },
      ctx
    );
    // console.log('response', response.data.data);

    const data = response.data.data;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};
const getUserFormatMediaLibrary = async (
  filter: {
    resolution: [number, number];
    duration: number;
    contentType: ILocationContentType;
  },
  ctx?: NextPageContext
): Promise<IMediaUpload[]> => {
  try {
    const response: AxiosResponse<{
      data: IMediaUpload[];
    }> = await API(
      {
        method: 'post',
        url: `users/me/media-library-format`,
        data: { filter },
      },
      ctx
    );
    // console.log('response', response.data.data);

    const data = response.data.data;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const getUserCompanyData = async (ctx?: NextPageContext): Promise<IUserOrganization | null> => {
  try {
    const user = await getUser(ctx || null);
    if (!user) {
      return null;
    }
    const response = await API(
      {
        method: 'get',
        url: `users/me/company`,
      },
      ctx
    );

    const data: IUserOrganization = response.data.data;
    return data;
  } catch (e: any) {
    throw new Error(e);
  }
};

const saveAvatar = async (avatar: string, ctx?: NextPageContext): Promise<any> => {
  try {
    return await API(
      {
        method: 'post',
        url: `users/update-avatar?v=v5`,
        data: { avatar },
      },
      ctx
    );
  } catch (e: any) {
    throw new Error(e);
  }
};
const getIndustries = async (ctx: NextPageContext): Promise<ICampaignIndustries[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `info/industries`,
      },
      ctx
    );
    const data: ICampaignIndustries[] = response.data.data.result;
    return data;
  } catch (e: any) {
    console.log('e', e);

    throw new Error(e);
  }
};

const getNetflix = async (ctx: NextPageContext): Promise<INetflix[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `screens/homepage`,
      },
      ctx
    );
    const data: INetflix[] = response.data.data;
    return data;
  } catch (e: any) {
    console.log('e', e);
    throw new Error(e);
  }
};

const getGeoCountries = async (ctx: NextPageContext): Promise<IGeoCountries[]> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `/info/geo/get-countries`,
      },
      ctx
    );
    const data: any[] = response.data[0][0];
    return data;
  } catch (e: any) {
    console.log('e', e);
    throw new Error(e);
  }
};

export type MondayLocation = {
  name: string;
  locationType: string;
  city: string;
  hasDeal: boolean;
  circuit: number;
  status: string;
  price: number;
  networkImages: number;
  address?: string;
  locationImages: number;
  Income_Per_Household_Code?: string;
  Race_Asian_Code?: string;
  Race_Black_Code?: string;
  Race_Hispanic_Code?: string;
  Race_White_Code?: string;
  Median_Age_Code?: string;

  location: {
    coordinates: number[];
    type: string;
  };
  venueType: number | null;
  id: string;
};

export type POI = {
  lat: number;
  lon: number;
  formattedAddress: string;
  types: string;
  location: {
    latitude: number;
    longitude: number;
  };
  displayName: {
    languageCode: string;
    text: string;
  };
};
export type MondayCampaign = {
  name: string;
  locationCount: number;
  data: MondayLocation[];
  pph: number;
  totalPrice: number;
  query?: any;
};

interface PricingResult {
  [locationId: string]: {
    totalPrice: string;
    slotIds: string[];
    playsPerHour: number;
  };
}

const checkLocations = async (searchParams: { [key: string]: any }, cancelToken: CancelToken): Promise<any> => {
  const response: AxiosResponse<{
    data: {
      data: MondayCampaign[];
    };
    poi: POI[];
  }> = await API({
    method: 'post',
    url: `/campaigns/monday/locations`,
    data: {
      search: JSON.stringify(searchParams),
    },
    cancelToken,
  });
  return response.data.data;
};

const checkDevLocations = async (searchParams: { [key: string]: any }, cancelTOken: CancelToken): Promise<any> => {
  const response: AxiosResponse<{
    data: {
      data: MondayCampaign[];
    };
    poi: POI[];
  }> = await API({
    method: 'post',
    url: `/campaigns/monday/locations-dev/search`,
    data: {
      search: JSON.stringify(searchParams),
    },
    cancelTOken,
  });
  return response.data.data;
};
const getCountLocations = async (searchParams: { [key: string]: any }, type: 'search' | 'count'): Promise<any> => {
  const response: AxiosResponse<{
    data: {
      totalHits: number;
    };
  }> = await API({
    method: 'post',
    url: `/campaigns/monday/locations-dev/${type}`,
    data: {
      search: JSON.stringify(searchParams),
    },
  });
  return response.data.data.totalHits;
};
const calculatePricesForLocations = async (
  locationsPayload: {
    locationIds: string[];
    pph: number;
    schedule: ScheduleDates[];
  }[]
): Promise<any> => {
  const response: AxiosResponse<{
    success: boolean;
    locationData: PricingResult;
    totalPrice: number;
  }> = await API({
    method: 'post',
    url: `/campaigns/monday/calculate-prices`,
    data: locationsPayload,
  });

  return response.data;
};
const mondayFetchCampaign = async (id: string, ctx: NextPageContext): Promise<MondayState> => {
  const response: AxiosResponse<{
    data: MondayState;
  }> = await API(
    {
      method: 'get',
      url: `/campaigns/monday/campaign-draft/${id}`,
    },
    ctx
  );
  return response.data.data;
};

const getMondayCampaignData = async (id: string, ctx: NextPageContext): Promise<any> => {
  const response: AxiosResponse<{
    formats: ICampaignFormat[];
  }> = await API(
    {
      method: 'get',
      url: `/campaigns/monday/campaign/${id}`,
    },
    ctx
  );
  return response.data;
};

const submitCampaign = async (state: MondayState): Promise<any> => {
  try {
    const sendData = {
      name: state.name,
      industry: state.industry,
      type: state.type,
      step: state.step,
      schedule: state.schedule,
      selectedCampaign: state.selectedCampaign,
      sentence: state.sentence,
      selectedCampaignIndex: state.selectedCampaignIndex,
      usedComponent: state.usedComponent,
      poi: state.poi,
      price: state.price || 0,
      campaignLocations: state.campaignLocations.map(el => el.uuid),
    };
    const response: AxiosResponse<{
      campaignId: string;
    }> = await API({
      method: 'post',
      url: `/campaigns/monday/saveCampaign`,
      data: {
        campaign: sendData,
        id: state.uuid,
      },
    });
    return response.data.campaignId;
  } catch (e: any) {
    return '0';
  }
};
const downloadFormat = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse<{
      campaignId: string;
    }> = await API({
      method: 'get',
      url: `/campaigns/monday/export-campaign/${id}`,
      data: {
        id,
      },
    });
    return response.data;
  } catch (e: any) {
    return '0';
  }
};

const updateMondayCampaign = async (id: string, campaign: string): Promise<any> => {
  try {
    const response: AxiosResponse<{
      campaignId: string;
    }> = await API({
      method: 'post',
      url: `/campaigns/monday/updateCampaign/${id}`,
      data: {
        campaign,
      },
    });
    return response.data.campaignId;
  } catch (e: any) {
    return '0';
  }
};

const mondayFetchDraft = async (locationIds: string[]) => {
  const response: AxiosResponse<{
    data: ILocationFull[];
  }> = await API(
    {
      method: 'post',
      url: `/campaigns/monday/fetch-locations`,
      data: {
        locationIds,
      },
    },
    null,
    {
      timeout: 480000,
    }
  );
  return response.data;
};

const mondayCreateDraft = async (locationIds: string[]) => {
  const response: AxiosResponse<{
    data: {
      uuid: string;
    };
  }> = await API(
    {
      method: 'post',
      url: `/campaigns/monday/create-campaign-by-ids`,
      data: {
        locations: locationIds,
      },
    },
    null,
    {
      timeout: 480000,
    }
  );
  return response.data;
};

const fetchLocationData = async (locationIds: string[]) => {
  const response: AxiosResponse<{
    data: {
      ok: 1;
      data: {
        data: MondayLocation[];
        poi: {
          lat: number;
          lng: number;
        }[];
      };
    };
  }> = await API({
    method: 'post',
    url: `/campaigns/monday/location-list`,
    data: {
      locationIds,
    },
  });
  return response.data.data;
};
const checkforFormats = async (campaignId: string) => {
  const response: AxiosResponse<{
    formats: ICampaignFormat[];
    media: IMondayMedia[];
    probeStatus: {
      status: string;
      updates: {
        added: number;
        updated: number;
      };
    };
  }> = await API({
    method: 'get',
    url: `/campaigns/monday/campaign/${campaignId}/check-formats`,
  });
  return response.data;
};

const createFetchCampaign = async (campaignId: string, ctx?: NextPageContext) => {
  const response: AxiosResponse<{
    id: {
      id: string;
    };
  }> = await API(
    {
      method: 'get',
      url: `/campaigns/monday/create-from-redis/${campaignId}`,
    },
    ctx
  );
  return response.data;
};

export default {
  recoverPassword,
  createFetchCampaign,
  checkforFormats,
  passwordChangeToken,
  updateMondayCampaign,
  getMondayCampaignData,
  validateCodeEmail,
  signin,
  register,
  getUserData,
  updateAccount,
  fetchLocationData,
  changeEmail,
  takeUser,
  registercompany,
  validateEmail,
  changePassword,
  getUserCompanyData,
  getIndustries,
  getUserCredit,
  setUserCompanyData,
  getUserInvoice,
  getInvoice,
  getUserPayment,
  removePayment,
  setDefaultPayment,
  addVoucher,
  confirmConvertVoucherCurrency,
  addPercentageVoucher,
  mondayFetchCampaign,
  mondayCreateDraft,
  getUserNotifications,
  checkLocations,
  calculatePricesForLocations,
  submitCampaign,
  mondayFetchDraft,
  resendCode,
  setUserNotifications,
  getNetflix,
  getGeoCountries,
  saveAvatar,
  getUserCreditsLog,
  getCountLocations,
  checkDevLocations,
  getNotificationsAll,
  getNotificationsNew,
  getUserMediaLibrary,
  getUserFormatMediaLibrary,
  downloadFormat,
  addSurveyAnswer,
  updateOnboarding,
};
