import React, { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import numeral from 'numeral';
import { Icon } from '@iconify/react-with-api';
import { PublishContext } from 'context/publish/publish.provider';
import { ISchedulerApiSubmit } from 'interface/IScheduler';
import { Campaigns } from 'api/campaigns/index';
import { IPublishType } from 'context/publish/publish.reducer';
import { ILocationReach } from 'interface/ILocationReach';
import { LocationWidget } from '../atoms';
//@ts-ignore
import { Iconly } from 'react-iconly';
import { oneLine } from 'common-tags';
import { ICampaignFormat, ILocationSchedule } from 'interface/ILocation';
import { DateTime } from 'luxon';
import { debounce } from 'lodash';
import { CAMPAIGNS } from '@api/index';
import { useRouter } from 'next/router';
import axios, { CancelTokenSource } from 'axios';

type IProps = {
  className?: string;
};
type IStats = {
  locations: number;
  devices: number;
  slots: number;
  plays: number;
  reach: number;
  price: string;
  loading: boolean;
};
type IStat = {
  icon: string;
  value: any;
  title: string;
  loading?: boolean;
  tag?: string;
};

const sepClasses = oneLine`
hidden md:block
w-px
bg-whiteish
dark:bg-bordercolordark
dark:bg-opacity-40
`;

const Stats = ({
  locations = 0,
  devices = 0,
  slots = 0,
  plays = 0,
  reach = 0,
  price = '0',
  loading = false,
}: IStats) => {
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const scrollCheck = () => {
    if (ref && ref.current) {
      setscrollX(ref.current.scrollLeft);
      if (Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <= ref.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    }
  };

  useEffect(() => {
    if (ref.current && ref?.current?.scrollWidth === ref?.current?.offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [ref?.current?.scrollWidth, ref?.current?.offsetWidth]);

  const scroll = (scrollOffset: any) => {
    if (ref && ref.current) {
      ref.current.scrollLeft += scrollOffset;
      setscrollX(scrollX + scrollOffset);

      if (Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <= ref.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    }
  };

  return (
    <div
      ref={ref}
      onScroll={scrollCheck}
      className="flex justify-between py-3 overflow-y-auto bg-white scroll-smooth overflox-x-scroll md:px-5 kpis-bar dark:bg-dark-200 md:flex-row-reverse md:rounded-b-3xl md:mx-7 md:overflow-hidden scrollbar-hidden border-gray dark:border-dark-100"
    >
      {scrollX !== 0 && (
        <div
          onClick={() => scroll(-100)}
          className="absolute right-0 z-10 flex items-center justify-center px-2 py-2 bg-white cursor-pointer top-1 dark:bg-dark-200 dark:text-dark-400 md:hidden"
        >
          <div className="flex items-center justify-center border-2 scroll-btn border-dark-300 rounded-2xl">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.796417 8.67725C0.655149 8.53876 0.642306 8.32204 0.757889 8.16933L0.796417 8.12558L3.98498 4.99984L0.796416 1.87409C0.655148 1.7356 0.642306 1.51889 0.757889 1.36618L0.796416 1.32243C0.937684 1.18394 1.15875 1.17135 1.31452 1.28466L1.35915 1.32243L4.82895 4.724C4.97022 4.86249 4.98306 5.07921 4.86748 5.23192L4.82895 5.27567L1.35915 8.67725C1.20376 8.82959 0.951811 8.82959 0.796417 8.67725Z"
                fill="#C1C5CA"
                stroke="#C1C5CA"
              />
            </svg>
          </div>
        </div>
      )}
      <Stat title="Budget (all taxes included)" value={price} icon="Wallet" loading={loading} tag="budget" />
      <div className={sepClasses}></div>
      <Stat title="Impressions" value={numeral(reach).format('0,0')} icon="Chart" loading={loading} tag="impressions" />
      <div className={sepClasses}></div>
      <Stat title="Total Plays" value={plays} icon="Activity" loading={loading} tag="plays" />
      <div className={sepClasses}></div>
      <Stat title="Hourly slots" value={slots} icon="Activity" loading={loading} tag="slots" />
      <div className={sepClasses}></div>
      <Stat title="Devices" value={devices} icon="Image" loading={loading} tag="devices" />
      <div className={sepClasses}></div>
      <Stat title="Locations" value={locations} icon="Location" loading={loading} tag="locations" />
      <div className="pl-10 md:hidden"></div>
      {!scrolEnd && (
        <div
          onClick={() => scroll(+100)}
          className="absolute left-0 flex items-center justify-center px-2 py-2 bg-white cursor-pointer top-1 dark:bg-dark-200 dark:text-dark-400 md:hidden"
        >
          <div className="flex items-center justify-center border-2 scroll-btn border-dark-300 rounded-2xl">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.20355 8.67725C5.34482 8.53876 5.35766 8.32204 5.24208 8.16933L5.20355 8.12558L2.01499 4.99984L5.20355 1.87409C5.34482 1.7356 5.35766 1.51889 5.24208 1.36618L5.20355 1.32243C5.06229 1.18394 4.84122 1.17135 4.68545 1.28466L4.64082 1.32243L1.17102 4.724C1.02975 4.86249 1.01691 5.07921 1.13249 5.23192L1.17102 5.27567L4.64082 8.67725C4.79621 8.82959 5.04816 8.82959 5.20355 8.67725Z"
                fill="#C1C5CA"
                stroke="#C1C5CA"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

const Stat = ({ icon, value, title, loading, tag }: IStat) => {
  return (
    <div className={`flex items-center  justify-between md:space-x-3 mob-bullet`}>
      <div
        className={`hidden md:flex items-center justify-center w-10 h-10 bg-grey-200 bg-opacity-20 dark:bg-dark-300 dark:bg-opacity-20 rounded-2xl`}
      >
        <span className="block dark:hidden">
          <Iconly name={icon} set="curved" primaryColor="#FDA53F" size="medium" />
        </span>
        <span className="hidden dark:block">
          <Iconly name={icon} set="curved" primaryColor="#E4DEDE" size="medium" />
        </span>
      </div>
      <div className="flex justify-center pl-4 pr-6 md:flex-col whitespace-nowrap md:whitespace-normal md:px-0 bullet">
        <div className={`md:text-xs text-body dark:text-dark-400 font-medium`}>
          {title}
          <span className="md:hidden">:</span>
        </div>
        <h4
          data-cy={tag}
          className={`flex items-center md:text-sm text-dark-default dark:text-dark-400  font-bold md:font-semibold md:font-bold ml-2 md:ml-0`}
        >
          {loading ? <img src="/images/loading.svg" width="20" height="20" /> : String(value) !== '0' ? value : '-'}
        </h4>
      </div>
    </div>
  );
};

const BreadCrumbs: React.FC<IProps> = ({ className }: IProps) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [state, setState] = React.useState<{
    price: string;
    locations: number;
    devices: number;
    slots: number;
    plays: number;
    reach: number;
  }>({
    price: '0',
    locations: 0,
    devices: 0,
    slots: 0,
    plays: 0,
    reach: 0,
  });

  const router = useRouter();
  const [loading, setLoading] = React.useState<boolean>(false);

  const saveCampaignDraft = debounce(async () => {
    if (loading) return;
    if (router.asPath.includes('publish/schedule')) await CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 4 });
  }, 5000);

  useEffect(() => {
    //save Draft

    calculatePrice();
    saveCampaignDraft();
  }, [publishState.toggleSlots, publishState.recalculateReach]);

  useEffect(() => {
    if (publishState.locations && publishState.locations.length > 0) {
      setState(prev => ({ ...prev, price: publishState.price }));
    }
    return () => {};
  }, [publishState.price]);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const refTimer = useRef<any>(null);
  const calculatePrice = debounce(() => {
    setLoading(true);
    if (loading) {
      if (refTimer.current) clearTimeout(refTimer.current);
      refTimer.current = setTimeout(() => {
        setLoading(false);
      }, 5000);
      return false;
    }
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('New request initiated');
    }
    if (publishState.locations && publishState.locations.length > 0) {
      const circuitsSum = publishState.locations.reduce((acc, el) => acc + el.location.circuit, 0);
      setState(prev => ({ ...prev, devices: circuitsSum, locations: publishState.locations?.length || 0 }));

      let totalSlots = 0;
      let totalPlays = 0;
      let submitData = [];

      const formatMap: {
        [key: string]: ICampaignFormat;
      } = {};
      publishState.formats.forEach(format => {
        format.locations.forEach(location => {
          formatMap[location] = format;
        });
      });

      if (publishState.schedule) {
        const cSlots = publishState.schedule
          .filter(sch => sch.uuid !== 'global' && sch.locations.length > 0)
          .reduce((acc, el) => {
            return acc + el.slots.length;
          }, 0);
        const cPlays = publishState.schedule
          .filter(sch => sch.uuid !== 'global' && sch.locations.length > 0)
          .reduce((acc, el) => {
            return acc + ((el.slots.length * 3600) / el.pph) * el.locations[0].circuit;
          }, 0);

        for (const item of publishState.schedule) {
          if (item.uuid !== 'global' && item.slots.length > 0 && item.locations.length > 0) {
            totalSlots += item.slots.length;

            const circuit = item.locations[0].circuit;

            const pph = item.pph;

            totalPlays += ((item.slots.length * 3600) / pph) * circuit;

            const duration = publishState.formats.find(format => format.locations.includes(item.locations[0].uuid));

            item.locations[0].duration = duration?.selectedDuration || duration?.durations[0] || 10;

            submitData.push({ locations: item.locations, slots: item.slots });
          }
        }
        if (submitData.length > 0) {
          Promise.allSettled([
            Campaigns.calculatePrice(submitData, cancelTokenSourceRef?.current?.token),
            Campaigns.calculateReach(submitData, cancelTokenSourceRef?.current?.token),
          ])
            .then(([priceData, reactCalculate]) => {
              if (reactCalculate.status === 'fulfilled' && publishState.locations) {
                for (const item of publishState.locations) {
                  const values = reactCalculate.value as ILocationReach[] | undefined;

                  const calculate = values?.find(el => el.location_id === item.location.uuid);
                  if (calculate) {
                    const durationObj = formatMap[item.location.uuid];
                    const pph = 3600 / (item.pph === 0 ? 3600 / item.location.frequencies[0].seconds : item.pph);
                    const durationFactor = durationObj
                      ? durationObj.selectedDuration / item.location.acceptedDurations.sort((a, b) => a - b)[0]
                      : 1;

                    const calc = calculate.total * pph * durationFactor;
                    item.reach = calc;
                    totalReach += calc;
                  }
                }
              }

              dispatch({
                type: IPublishType.setAgregateData,
                payload: {
                  price: priceData.status === 'fulfilled' ? priceData.value : '0',
                  reach: totalReach,
                },
              });
              setState(prev => ({
                ...prev,
                slots: totalSlots,
                plays: totalPlays,
                price: priceData.status === 'fulfilled' ? priceData.value : '0',
                reach: totalReach,
              }));
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);

          setState(prev => ({
            ...prev,
            slots: 0,
            plays: 0,
            price: '0',
            reach: 0,
          }));
        }
      } else {
        setLoading(false);

        setState(prev => ({
          ...prev,
          slots: 0,
          plays: 0,
          price: '0',
          reach: 0,
        }));
      }
    } else {
    }

    // setState(prev => ({ ...prev, slots: totalSlots, plays: totalPlays }));

    let data = '0';
    let totalReach = 0;
  }, 500);

  return (
    <div className={className}>
      <Stats
        locations={state.locations}
        devices={state.devices}
        slots={state.slots}
        plays={state.plays}
        reach={state.reach}
        price={state.price}
        loading={loading}
      />
    </div>
  );
};

export default memo(BreadCrumbs);
